var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ticketMain" },
    [
      _c(
        "div",
        { staticClass: "ticketLeft" },
        [
          _c("head-layout", { attrs: { "head-title": "作业票流程" } }),
          _c(
            "div",
            { staticClass: "ticketStep" },
            [
              _c(
                "el-steps",
                { attrs: { direction: "vertical", active: _vm.ticketStep } },
                [
                  _c("el-step", {
                    attrs: { title: "申请" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.runJump("draft")
                      },
                    },
                  }),
                  _c("el-step", {
                    attrs: { title: "安全交底" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.runJump("disclose")
                      },
                    },
                  }),
                  _c("el-step", {
                    attrs: { title: "安全监管" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.runJump("supervise")
                      },
                    },
                  }),
                  _c("el-step", {
                    attrs: { title: "验收确认" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.runJump("confirm")
                      },
                    },
                  }),
                  _c("el-step", {
                    attrs: { title: "完成" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.runJump("confirm")
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "ticketRight" }, [
        _c(
          "div",
          { staticClass: "ticketHead" },
          [
            _c("head-layout", {
              attrs: {
                "head-title": "作业票基本信息",
                "head-btn-options": _vm.headBtnOptions,
              },
              on: {
                "head-forward": _vm.handleForward,
                "head-save": function ($event) {
                  return _vm.headSave("saveData")
                },
                "head-refer": _vm.headRefer,
                "head-cancel": _vm.headCancel,
              },
            }),
            _c(
              "el-button",
              { staticClass: "ticketHeadBtn", attrs: { type: "primary" } },
              [_vm._v("临时用电安全作业票")]
            ),
            _c(
              "el-form",
              {
                staticClass: "ticketHeadCode",
                attrs: { model: _vm.dataForm, "label-width": "100px" },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "作业票编号:", prop: "ticketCode" } },
                  [
                    _c("el-input", {
                      staticStyle: { width: "300px" },
                      attrs: {
                        placeholder: "请输入作业票编号",
                        disabled: true,
                      },
                      model: {
                        value: _vm.dataForm.ticketCode,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataForm, "ticketCode", $$v)
                        },
                        expression: "dataForm.ticketCode",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("div", { staticClass: "ticketContent" }, [
          _c(
            "div",
            { staticClass: "ticketForm", attrs: { id: "draft" } },
            [
              _c(
                "el-form",
                {
                  ref: "formLayout",
                  attrs: {
                    disabled: ["view", "flow", "sign", "workflow"].includes(
                      _vm.type
                    ),
                    model: _vm.dataForm,
                    rules: _vm.rules,
                    "label-width": "140px",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "作业申请单位:",
                                prop: "requirOrg",
                              },
                            },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: {
                                    placeholder: "请选择/输入作业申请单位",
                                    maxlength: "255",
                                    "show-word-limit": "",
                                  },
                                  model: {
                                    value: _vm.dataForm.requirOrg,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.dataForm, "requirOrg", $$v)
                                    },
                                    expression: "dataForm.requirOrg",
                                  },
                                },
                                [
                                  _c("el-button", {
                                    attrs: {
                                      slot: "append",
                                      icon: "el-icon-search",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openDeptBelow("requirOrg")
                                      },
                                    },
                                    slot: "append",
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "作业申请时间:",
                                prop: "requirTime",
                              },
                            },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "datetime",
                                  format: "yyyy-MM-dd HH:mm:ss",
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  placeholder: "请选择作业申请时间",
                                },
                                model: {
                                  value: _vm.dataForm.requirTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "requirTime", $$v)
                                  },
                                  expression: "dataForm.requirTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "作业负责人:",
                                prop: "respNickName",
                                "label-width": "200px",
                              },
                            },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: {
                                    placeholder: "请选择/输入作业负责人",
                                    readonly: "",
                                    maxlength: "64",
                                    "show-word-limit": "",
                                  },
                                  model: {
                                    value: _vm.dataForm.respNickName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.dataForm,
                                        "respNickName",
                                        $$v
                                      )
                                    },
                                    expression: "dataForm.respNickName",
                                  },
                                },
                                [
                                  _c("el-button", {
                                    attrs: {
                                      slot: "append",
                                      icon: "el-icon-search",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openUser("respUser")
                                      },
                                    },
                                    slot: "append",
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "关联作业票:",
                                prop: "relatedWt",
                              },
                            },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: {
                                    placeholder: "请选择作业票",
                                    maxlength: "255",
                                    "show-word-limit": "",
                                    readonly: "",
                                  },
                                  model: {
                                    value: _vm.dataForm.relatedWt,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.dataForm, "relatedWt", $$v)
                                    },
                                    expression: "dataForm.relatedWt",
                                  },
                                },
                                [
                                  _c("el-button", {
                                    attrs: {
                                      slot: "append",
                                      icon: "el-icon-search",
                                    },
                                    on: { click: _vm.relatedWt },
                                    slot: "append",
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "安全交底人:",
                                prop: "securityDisclosureName",
                              },
                            },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: {
                                    placeholder: "请选择/输入安全交底人",
                                    readonly: "",
                                    maxlength: "255",
                                    "show-word-limit": "",
                                  },
                                  model: {
                                    value: _vm.dataForm.securityDisclosureName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.dataForm,
                                        "securityDisclosureName",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "dataForm.securityDisclosureName",
                                  },
                                },
                                [
                                  _c("el-button", {
                                    attrs: {
                                      slot: "append",
                                      icon: "el-icon-search",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openUser(
                                          "securityDisclosureName"
                                        )
                                      },
                                    },
                                    slot: "append",
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "作业负责人电工证号:",
                                prop: "respLicense",
                                "label-width": "200px",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "自动带出",
                                  disabled: true,
                                  maxlength: "255",
                                  "show-word-limit": "",
                                },
                                model: {
                                  value: _vm.dataForm.respLicense,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "respLicense", $$v)
                                  },
                                  expression: "dataForm.respLicense",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "监护人:", prop: "guardianName" },
                            },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: {
                                    placeholder: "请选择/输入监护人",
                                    maxlength: "255",
                                    "show-word-limit": "",
                                  },
                                  model: {
                                    value: _vm.dataForm.guardianName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.dataForm,
                                        "guardianName",
                                        $$v
                                      )
                                    },
                                    expression: "dataForm.guardianName",
                                  },
                                },
                                [
                                  _c("el-button", {
                                    attrs: {
                                      slot: "append",
                                      icon: "el-icon-search",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openUser("guardian")
                                      },
                                    },
                                    slot: "append",
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "作业地点:", prop: "location" } },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: {
                                    maxlength: "255",
                                    "show-word-limit": "",
                                    placeholder: "请选择/请输入作业地点",
                                  },
                                  model: {
                                    value: _vm.dataForm.location,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.dataForm, "location", $$v)
                                    },
                                    expression: "dataForm.location",
                                  },
                                },
                                [
                                  _c("el-button", {
                                    attrs: {
                                      slot: "append",
                                      icon: "el-icon-search",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.operatingLocation()
                                      },
                                    },
                                    slot: "append",
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "电源接入点及许可用电功率:",
                                prop: "accessPower",
                                "label-width": "200px",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "64",
                                  "show-word-limit": "",
                                  placeholder: "请输入电源接入点及许可用电功率",
                                },
                                model: {
                                  value: _vm.dataForm.accessPower,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "accessPower", $$v)
                                  },
                                  expression: "dataForm.accessPower",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "用电人:", prop: "elecNickName" },
                            },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: {
                                    placeholder: "请选择/输入用电人",
                                    maxlength: "64",
                                    "show-word-limit": "",
                                  },
                                  model: {
                                    value: _vm.dataForm.elecNickName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.dataForm,
                                        "elecNickName",
                                        $$v
                                      )
                                    },
                                    expression: "dataForm.elecNickName",
                                  },
                                },
                                [
                                  _c("el-button", {
                                    attrs: {
                                      slot: "append",
                                      icon: "el-icon-search",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openUser("elecUser")
                                      },
                                    },
                                    slot: "append",
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "工作电压:", prop: "voltage" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "64",
                                  "show-word-limit": "",
                                  placeholder: "请输入工作电压",
                                },
                                model: {
                                  value: _vm.dataForm.voltage,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "voltage", $$v)
                                  },
                                  expression: "dataForm.voltage",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "用电设备名称及额定功率:",
                                "label-width": "200px",
                                prop: "equipmentPower",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "64",
                                  "show-word-limit": "",
                                  placeholder: "请输入用电设备名称及额定功率",
                                },
                                model: {
                                  value: _vm.dataForm.equipmentPower,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dataForm,
                                      "equipmentPower",
                                      $$v
                                    )
                                  },
                                  expression: "dataForm.equipmentPower",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "作业内容:", prop: "content" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  autosize: { minRows: 4, maxRows: 4 },
                                  maxlength: "500",
                                  "show-word-limit": "",
                                  placeholder: "请输入作业内容",
                                },
                                model: {
                                  value: _vm.dataForm.content,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "content", $$v)
                                  },
                                  expression: "dataForm.content",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 14 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "临时用电作业时间:",
                                prop: "timeInterval",
                              },
                            },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "datetimerange",
                                  format: "yyyy-MM-dd HH:mm",
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  "range-separator": "至",
                                  "start-placeholder": "开始日期",
                                  "end-placeholder": "结束日期",
                                },
                                model: {
                                  value: _vm.dataForm.timeInterval,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "timeInterval", $$v)
                                  },
                                  expression: "dataForm.timeInterval",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "风险辨识结果:",
                                prop: "wtRiskList",
                              },
                            },
                            [
                              _c(
                                "el-checkbox-group",
                                {
                                  model: {
                                    value: _vm.dataForm.wtRiskList,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.dataForm, "wtRiskList", $$v)
                                    },
                                    expression: "dataForm.wtRiskList",
                                  },
                                },
                                _vm._l(
                                  _vm.riskIdentResults,
                                  function (item, index) {
                                    return _c(
                                      "el-checkbox",
                                      {
                                        key: index,
                                        attrs: { label: item.optionCode },
                                        on: {
                                          change: function ($event) {
                                            return _vm.wtRiskChanger(item, "")
                                          },
                                        },
                                      },
                                      [
                                        item.optionCode == "other"
                                          ? _c(
                                              "div",
                                              [
                                                _vm._v(
                                                  "\n                      其他:\n                      "
                                                ),
                                                _c("el-input", {
                                                  attrs: {
                                                    maxlength: "255",
                                                    "show-word-limit": "",
                                                    placeholder: "请输入其他",
                                                    disabled: _vm.wtRiskThow,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.wtRiskChanger(
                                                        item,
                                                        "input"
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: _vm.wtRiskOther,
                                                    callback: function ($$v) {
                                                      _vm.wtRiskOther = $$v
                                                    },
                                                    expression: "wtRiskOther",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _c("div", [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(item.optionName) +
                                                  "\n                    "
                                              ),
                                            ]),
                                      ]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "辨识人签字:" } },
                            [
                              _c("securitySignature", {
                                ref: "securitySignature",
                                attrs: {
                                  isShowHeader: false,
                                  securityType: _vm.$route.query.type,
                                  doned: _vm.$route.query.doned == "1",
                                  businessStatus: _vm.businessStatus,
                                  list: [
                                    { imgUrl: _vm.dataForm.recognizePeople },
                                  ],
                                },
                                on: {
                                  getSecuritySignature: function ($event) {
                                    return _vm.getSecuritySignature(
                                      $event,
                                      "recognizePeople"
                                    )
                                  },
                                  deleteImg: function ($event) {
                                    return _vm.deleteImgSecure(
                                      "recognizePeople"
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "ticketGas" },
            [
              _c("head-layout", {
                attrs: {
                  "head-title": "作业人及电工证号",
                  "head-btn-options": _vm.operatorHeadBtn,
                },
                on: { "head-add": _vm.addOperator },
              }),
              _c(
                "div",
                { staticClass: "gasTable" },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.wtUserList,
                        border: "",
                        size: "medium",
                        height: 300,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "序号",
                          type: "index",
                          align: "center",
                          width: "50",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "userName",
                          align: "center",
                          label: "人员姓名",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "userDept",
                          align: "center",
                          label: "所属组织",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "userLicense",
                          align: "center",
                          label: "证书编号",
                        },
                      }),
                      ["edit", "add"].includes(_vm.type)
                        ? _c("el-table-column", {
                            attrs: {
                              label: "操作",
                              align: "center",
                              width: "100",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "text",
                                            size: "small",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteOperator(
                                                scope.row
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              847424352
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "ticketGas" },
            [
              _c("head-layout", {
                attrs: {
                  "head-title": "安全监管人",
                  "head-btn-options": _vm.superviseHeadBtn,
                },
                on: { "head-add": _vm.addSupervise },
              }),
              _c(
                "div",
                { staticClass: "gasTable" },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.safetySupervisorList,
                        border: "",
                        height: 300,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "序号",
                          type: "index",
                          align: "center",
                          width: "50",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "conservatorName",
                          align: "center",
                          label: "监管人名称",
                        },
                      }),
                      ["edit", "add"].includes(_vm.type)
                        ? _c("el-table-column", {
                            attrs: {
                              label: "操作",
                              align: "center",
                              width: "100",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "text",
                                            size: "small",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteSupervise(
                                                scope.row
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1984254984
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "ticketGas" },
            [
              _c("head-layout", {
                attrs: {
                  "head-title":
                    "可燃气体分析（运行的生产装置、罐区和具有火灾爆炸危险场所）",
                  "head-btn-options": _vm.gasHeadBtn,
                },
                on: { "head-add": _vm.addGas },
              }),
              _c(
                "div",
                { staticClass: "gasTable" },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { data: _vm.wtGasList, border: "", height: 300 },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "序号",
                          type: "index",
                          align: "center",
                          prop: "seq",
                          width: "50",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "sampleResult",
                          align: "center",
                          label: "可燃气体检测结果",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "请输入可燃气体检测结果",
                                    maxlength: "255",
                                    "show-word-limit": "",
                                    disabled: [
                                      "view",
                                      "flow",
                                      "sign",
                                      "workflow",
                                    ].includes(_vm.type),
                                  },
                                  model: {
                                    value: scope.row.sampleResult,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "sampleResult", $$v)
                                    },
                                    expression: "scope.row.sampleResult",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "sampleTime",
                          align: "center",
                          label: "分析时间",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-date-picker", {
                                  attrs: {
                                    type: "datetime",
                                    format: "yyyy-MM-dd HH:mm:ss",
                                    "value-format": "yyyy-MM-dd HH:mm:ss",
                                    placeholder: "请选择分析时间",
                                    disabled: [
                                      "view",
                                      "flow",
                                      "sign",
                                      "workflow",
                                    ].includes(_vm.type),
                                  },
                                  model: {
                                    value: scope.row.sampleTime,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "sampleTime", $$v)
                                    },
                                    expression: "scope.row.sampleTime",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "sampleLocation",
                          align: "center",
                          label: "分析点",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "请输入分析点",
                                    maxlength: "255",
                                    "show-word-limit": "",
                                    disabled: [
                                      "view",
                                      "flow",
                                      "sign",
                                      "workflow",
                                    ].includes(_vm.type),
                                  },
                                  model: {
                                    value: scope.row.sampleLocation,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "sampleLocation", $$v)
                                    },
                                    expression: "scope.row.sampleLocation",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "sampleUser",
                          align: "center",
                          label: "分析人",
                          width: "200",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.sampleUser
                                  ? _c("img", {
                                      staticClass: "signatureImg",
                                      attrs: { src: scope.row.sampleUser },
                                    })
                                  : _vm._e(),
                                scope.row.sampleUser == "" &&
                                ["edit", "add"].includes(_vm.type)
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { size: "small" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.openSignature(
                                              scope.$index,
                                              "wtGasList"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  签名\n                "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                      ["edit", "add", "sign"].includes(_vm.type)
                        ? _c("el-table-column", {
                            attrs: {
                              label: "操作",
                              align: "center",
                              width: "100",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "text",
                                            size: "small",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteGas(
                                                scope.$index,
                                                _vm.wtGasList
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              754560189
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "ticketSecure" },
            [
              _c("head-layout", {
                attrs: {
                  "head-title": "安全措施",
                  "head-btn-options": _vm.secureHeadBtn,
                },
                on: { "head-add": _vm.addSecure },
              }),
              _c(
                "div",
                { staticClass: "gasTable" },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.wtSecurityPreventionList,
                        border: "",
                        height: 300,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "序号",
                          type: "index",
                          align: "center",
                          width: "50",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "securityContent",
                          align: "center",
                          label: "安全措施",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "请输入安全措施",
                                    type: "textarea",
                                    autosize: { minRows: 1, maxRows: 6 },
                                    maxlength: "255",
                                    "show-word-limit": "",
                                    disabled: [
                                      "view",
                                      "flow",
                                      "sign",
                                      "workflow",
                                    ].includes(_vm.type),
                                  },
                                  model: {
                                    value: scope.row.securityContent,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        scope.row,
                                        "securityContent",
                                        $$v
                                      )
                                    },
                                    expression: "scope.row.securityContent",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "invovedFlag",
                          align: "center",
                          width: "100",
                          label: "是否涉及",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.type != "other"
                                  ? _c("el-switch", {
                                      attrs: {
                                        "active-value": "1",
                                        "inactive-value": "0",
                                        "active-text": "是",
                                        disabled: [
                                          "view",
                                          "flow",
                                          "sign",
                                          "workflow",
                                        ].includes(_vm.type),
                                        "inactive-text": "否",
                                      },
                                      model: {
                                        value: scope.row.invovedFlag,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "invovedFlag",
                                            $$v
                                          )
                                        },
                                        expression: "scope.row.invovedFlag",
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                      ["edit", "add"].includes(_vm.type)
                        ? _c("el-table-column", {
                            attrs: {
                              label: "操作",
                              align: "center",
                              width: "100",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return scope.row.type != "other"
                                      ? [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "text",
                                                size: "small",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteSecure(
                                                    scope.$index,
                                                    _vm.wtSecurityPreventionList
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("删除\n                ")]
                                          ),
                                        ]
                                      : undefined
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "ticketDisclose" },
            [
              _c("head-layout", { attrs: { "head-title": "安全措施签名" } }),
              _c("securitySignature", {
                ref: "securitySignature",
                attrs: {
                  securityType: _vm.$route.query.type,
                  doned: _vm.$route.query.doned == "1",
                  businessStatus: _vm.businessStatus,
                  list: [{ imgUrl: _vm.dataForm.measureSignatureUrl }],
                },
                on: {
                  deleteImg: function ($event) {
                    return _vm.deleteImgSecure("measureSignatureUrl")
                  },
                  getSecuritySignature: function ($event) {
                    return _vm.getSecuritySignature(
                      $event,
                      "measureSignatureUrl"
                    )
                  },
                },
              }),
            ],
            1
          ),
          _vm.processFlowList.length != 0
            ? _c(
                "div",
                { staticClass: "ticketGas" },
                [
                  _c("head-layout", { attrs: { "head-title": "作业票审批" } }),
                  _c(
                    "div",
                    { staticClass: "gasTable" },
                    [
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            data: _vm.processFlowList,
                            border: "",
                            height: 300,
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: "序号",
                              type: "index",
                              align: "center",
                              width: "50",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "assigneeName",
                              align: "center",
                              label: "审批人",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "comments",
                              align: "center",
                              label: "审批意见",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.comments.length != 0
                                        ? _c(
                                            "el-tag",
                                            { attrs: { type: "danger" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  scope.row.comments[0].message
                                                ) + "\n                "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1364604568
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "historyActivityType",
                              align: "center",
                              label: "审批结果",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("el-tag", [
                                        _vm._v(
                                          _vm._s(
                                            scope.row.historyActivityType ==
                                              "endEvent"
                                              ? "结束"
                                              : scope.row.historyActivityName ||
                                                  "未命名"
                                          ) + "\n                "
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              762185034
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "endTime",
                              align: "center",
                              label: "审批时间",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          ["DISCLOSED", "SUPERVISE", "CHECKACCEPT", "FINISHED"].includes(
            _vm.businessStatus
          ) &&
          ["supervise", "safeConfessor", "respUser", "drafter"].includes(
            _vm.characters
          )
            ? _c(
                "div",
                { staticClass: "ticketGas", attrs: { id: "disclose" } },
                [
                  _c("head-layout", {
                    attrs: { "head-title": "安全交底" },
                    on: { "head-add": _vm.addDisclose },
                  }),
                  _c(
                    "div",
                    { staticClass: "gasTable" },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "discloseForm",
                          attrs: {
                            model: _vm.discloseForm,
                            rules: _vm.discloseRules,
                            "label-width": "100px",
                            disabled:
                              _vm.$route.query.doned != "1" &&
                              (_vm.type == "view" ||
                                _vm.businessStatus != "DISCLOSED"),
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "上传附件", prop: "documentUrl" },
                            },
                            [
                              _c(
                                "el-upload",
                                {
                                  attrs: {
                                    action:
                                      "/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform",
                                    disabled:
                                      _vm.type == "view" &&
                                      _vm.businessStatus != "DISCLOSED",
                                    headers: _vm.headers,
                                    "on-success": _vm.discloseSuc,
                                    "on-remove": _vm.discloseRem,
                                    "on-preview": _vm.downloadFile,
                                    multiple: "",
                                    limit: 3,
                                    "file-list": _vm.documentUrl,
                                  },
                                },
                                [
                                  _vm.type != "view" &&
                                  _vm.businessStatus == "DISCLOSED"
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "small",
                                            type: "primary",
                                          },
                                        },
                                        [_vm._v("点击上传")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          ["DISCLOSED", "SUPERVISE", "CHECKACCEPT", "FINISHED"].includes(
            _vm.businessStatus
          ) &&
          ["supervise", "safeConfessor", "respUser", "drafter"].includes(
            _vm.characters
          )
            ? _c(
                "div",
                { staticClass: "ticketDisclose-no-margin" },
                [
                  _c("securityDisclosure", {
                    ref: "securityDisclosure",
                    attrs: {
                      securityType: _vm.$route.query.type,
                      doned: _vm.$route.query.doned == "1",
                      businessStatus: _vm.businessStatus,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          ["SUPERVISE", "CHECKACCEPT", "FINISHED"].includes(
            _vm.businessStatus
          ) &&
          ["supervise", "safeConfessor", "respUser", "drafter"].includes(
            _vm.characters
          )
            ? _c(
                "div",
                { staticClass: "ticketGas", attrs: { id: "supervise" } },
                [
                  _c("superviseImg", {
                    ref: "superviseImg",
                    attrs: {
                      disabled:
                        _vm.$route.query.doned != "1" &&
                        _vm.type == "flow" &&
                        _vm.characters == "supervise",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          ["CHECKACCEPT", "FINISHED"].includes(_vm.businessStatus) &&
          ["supervise", "safeConfessor", "respUser", "drafter"].includes(
            _vm.characters
          )
            ? _c(
                "div",
                { staticClass: "ticketGas", attrs: { id: "confirm" } },
                [
                  _c("finishWorkImg", {
                    ref: "finishWorkImg",
                    attrs: {
                      disabled:
                        _vm.$route.query.doned != "1" &&
                        _vm.type == "flow" &&
                        _vm.characters == "respUser",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "安全交底",
            visible: _vm.discloseShow,
            top: "9vh",
            width: "40%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.discloseShow = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "discloseForm",
              attrs: {
                model: _vm.discloseForm,
                rules: _vm.discloseRules,
                "label-width": "100px",
                disabled: _vm.discloseType == "view",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "交底人", prop: "disclosureName" } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { placeholder: "请选择交底人" },
                      model: {
                        value: _vm.discloseForm.disclosureName,
                        callback: function ($$v) {
                          _vm.$set(_vm.discloseForm, "disclosureName", $$v)
                        },
                        expression: "discloseForm.disclosureName",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: { slot: "append", icon: "el-icon-search" },
                        on: {
                          click: function ($event) {
                            return _vm.openUser("disclosureName")
                          },
                        },
                        slot: "append",
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "日期时间", prop: "disclosureTime" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetime",
                      format: "yyyy-MM-dd HH:mm:ss",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      placeholder: "请选择日期时间",
                    },
                    model: {
                      value: _vm.discloseForm.disclosureTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.discloseForm, "disclosureTime", $$v)
                      },
                      expression: "discloseForm.disclosureTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "交底内容", prop: "remark" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", placeholder: "请输入交底内容" },
                    model: {
                      value: _vm.discloseForm.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.discloseForm, "remark", $$v)
                      },
                      expression: "discloseForm.remark",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "相关文件", prop: "documentUrl" } },
                [
                  _c(
                    "el-upload",
                    {
                      attrs: {
                        action:
                          "/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform",
                        disabled: _vm.discloseType == "view",
                        headers: _vm.headers,
                        "on-success": _vm.discloseSuc,
                        "on-remove": _vm.discloseRem,
                        "on-preview": _vm.downloadFile,
                        multiple: "",
                        limit: 3,
                        "file-list": _vm.documentUrl,
                      },
                    },
                    [
                      _c(
                        "el-button",
                        { attrs: { size: "small", type: "primary" } },
                        [_vm._v("点击上传")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.discloseShow = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm.discloseType != "view"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitDisclse },
                    },
                    [_vm._v("确 定")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("jobTicketImage", {
        ref: "jobTicketImage",
        attrs: { title: "在线预览" },
      }),
      _vm.signatureShow
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "电子签名",
                visible: _vm.signatureShow,
                width: "1000px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.signatureShow = $event
                },
              },
            },
            [_c("signature", { on: { getSignatureImg: _vm.getSignatureImg } })],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "部门选择",
            visible: _vm.deptBelowShow,
            width: "1000px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.deptBelowShow = $event
            },
          },
        },
        [
          _c("currentDepartmentBelow", {
            on: { getDeptBelow: _vm.getDeptBelow },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "人员选择",
            visible: _vm.userShow,
            top: "9vh",
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.userShow = $event
            },
          },
        },
        [
          _vm.userShow
            ? _c("ProjectSelectUser", {
                attrs: {
                  multiple: _vm.userOpentype == "securityDisclosureName",
                },
                on: { "select-data": _vm.getUser, closeDia: _vm.closeUser },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "人员选择",
            visible: _vm.userListShow,
            top: "9vh",
            width: "80%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.userListShow = $event
            },
          },
        },
        [
          _vm.userListShow
            ? _c("UserDeptMultipleDialog", {
                ref: "userDeptMultipleDialog",
                on: {
                  "select-all-data": _vm.getUserList,
                  closeDia: _vm.closeDia,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.jobTicketDialogShow
        ? _c(
            "common-dialog",
            {
              attrs: {
                dialogTitle: "作业票清单",
                width: "60%",
                showConfirmBtn: false,
              },
              on: {
                cancel: function ($event) {
                  _vm.jobTicketDialogShow = false
                },
              },
            },
            [
              _c("jobTicketDialog", {
                ref: "jobTicketDialog",
                attrs: { ticketCode: _vm.dataForm.ticketCode },
                on: { submitData: _vm.submitData },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("process-user-dialog", {
        ref: "processUser",
        on: {
          getUserSelection: _vm.handleUserSelection,
          closeProcess: _vm.closeProcess,
        },
      }),
      _c("riskArea", {
        ref: "riskArea",
        staticClass: "riskAreaClass",
        attrs: { chooseType: "radio" },
        on: { areaSelect: _vm.areaSelect },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }